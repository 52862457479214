/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.about-page {
  min-height: 100vh;
  width: 100%;
  background-image: url(../../images/bg_about.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-page-bg {
  -webkit-transition: background-image 1s ease-in-out;
  -o-transition: background-image 1s ease-in-out;
  transition: background-image 1s ease-in-out;
  height: 100%;
  width: 100%;
  background-image: url(../../images/bg_about_t.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  margin: -25px;
  padding-top: 30px;
  padding-bottom: 230px;
  padding-left: 30px;
  padding-right: 30px;
}

.about_page__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.508);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 15px;
}

.about_page__block__art-competitions {
  padding: 20px 0;
}

.about_page__block__content {
  text-align: justify;
  line-height: 1.5;
}
.about_page__block__list {
  line-height: 1.5;
}
.fb-page {
  width: 100%;
}
.about_page__block__images-container {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-gap: 20px;
}
.about_page__block__images-container__img {
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
}
.first-img {
  margin: 20px 0 20px 20px;
}
.second-img {
  margin: 20px 50px 10px 0;
}

@media (max-width: 1279px) {
  .about-page-bg {
    background: none;
  }
}
