/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.second-step {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #111;
}

.second-step__title {
  color: #fff;
  margin-bottom: 20px;
}

.second-step__pictures-block {
  display: -ms-grid;
  display: grid; /* Добавили grid */
  -ms-grid-rows: auto 40px auto 40px auto;
  grid-template-rows: auto auto auto; /* Добавили строки для элементов */
  -ms-grid-columns: 1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr 40px 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas:
    "one-p one-p two-p two-p three-p three-p"
    "one-p one-p six-p six-p three-p three-p"
    "four-p five-p six-p six-p seven-p eight-p";
  grid-gap: 40px;
  margin-bottom: 20px;
}
.second-step__pictures-block div img {
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  cursor: pointer;
  border-radius: 3px;
}
.second-step__pictures-block div img:hover{
  -webkit-transform: scale(1.02);
      -ms-transform: scale(1.02);
          transform: scale(1.02);
  -webkit-box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.4);
box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.4);
}

.second-step__pictures-block__one-p {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-area: one-p;
}
.second-step__pictures-block__one-p img {
  max-height: 400px;
}

.second-step__pictures-block__two-p {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
  -ms-grid-column-span: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-area: two-p;
}
.second-step__pictures-block__two-p img {
  max-height: 300px;
}
.second-step__pictures-block__three-p {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 9;
  -ms-grid-column-span: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-area: three-p;
}
.second-step__pictures-block__three-p img{
  max-height: 400px;
}
.second-step__pictures-block__four-p {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-area: four-p;
}
.second-step__pictures-block__five-p {
  -ms-grid-row: 5;
  -ms-grid-column: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-area: five-p;
}
.second-step__pictures-block__six-p {
  -ms-grid-row: 3;
  -ms-grid-row-span: 3;
  -ms-grid-column: 5;
  -ms-grid-column-span: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-area: six-p;
}
.second-step__pictures-block__six-p img{
  max-height: 350px;
}
.second-step__pictures-block__seven-p {
  -ms-grid-row: 5;
  -ms-grid-column: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-area: seven-p;
}
.second-step__pictures-block__seven-p img{
  max-height: 300px;
}
.second-step__pictures-block__eight-p {
  -ms-grid-row: 5;
  -ms-grid-column: 11;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-area: eight-p;
}
.second-step__pictures-block__eight-p img{
  max-height: 300px;
}

.second-step__button {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
font-weight: 600;
}

.second-step__button a {
  position: relative;
  padding: 8px;
  -webkit-transition: -webkit-box-shadow 0.2s ease;
  transition: -webkit-box-shadow 0.2s ease;
  -o-transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease, -webkit-box-shadow 0.2s ease;
}

.second-step__button a:last-child {
  margin-right: 0;
}

.second-step__button a:hover {
  -webkit-box-shadow: 0 0 0 2px #fff;
          box-shadow: 0 0 0 2px #fff; /* Добавляем белую рамку вокруг активной ссылки */
  border-radius: 2px;
}

.second-step__button a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 2px;
  background-color: #fff;
}

.second-step__button a:hover::before {
  display: none;
}

@media (max-width: 767px) {
  .second-step__pictures-block {
    -ms-grid-columns: 1fr 40px 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto 40px auto 40px auto 40px auto;
        grid-template-areas:
      "one-p two-p"
      "three-p four-p"
      "five-p six-p"
      "seven-p eight-p";
  }
  .second-step__pictures-block__one-p {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .second-step__pictures-block__two-p {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
  }
  .second-step__pictures-block__three-p {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .second-step__pictures-block__four-p {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }
  .second-step__pictures-block__five-p {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
  }
  .second-step__pictures-block__six-p {
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
  }
  .second-step__pictures-block__seven-p {
    -ms-grid-row: 7;
    -ms-grid-column: 1;
  }
  .second-step__pictures-block__eight-p {
    -ms-grid-row: 7;
    -ms-grid-column: 3;
  }
}