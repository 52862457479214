/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.fourth-step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: hidden;
}
.fourth-step__content {
  background-color: #00000083;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
}

.fourth-step__content__text {
  text-align: justify;
  font-size: 20px;
  color: #ffffff;
}

.fourth-step__bg {
  z-index: -2;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  background-image: url(../../../images/bg2_bg.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.fourth-step__bg__mountins {
  position: relative;
  z-index: -1;
  width: 100%;
  height: 67%;
  background-image: url(../../../images/bg2_mountins.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: inherit;
}
