/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.third-step {
  width: 100%;
  background-color: #111;
}
.third-step__bio {
  margin-bottom: -1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
  width: 100%;
}
.third-step__bio__title {
  color: #fff;
  margin-top: 0;
  font-style: italic;
  font-size: 41px;
  margin-bottom: 20px;
}

.third-step__bio__first-content-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-row-gap: 20px;
  grid-column-gap: 80px;
}

.third-step__bio__first-content-block__img img {
  width: 300px;
  border-radius: 20px;
}

.third-step__bio__first-content-block__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
  width: 100%;
}

.third-step__bio__first-content-block__text__title {
  font-size: 27px;

  margin-bottom: 20px;
}
.third-step__bio__first-content-block__text__content {
  text-align: justify;
  font-size: 20px;
}

.third-step__bio__second-content-block {
  height: 100%;
  background-image: url(../../../images/bg2-bio.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.third-step__bio__second-content-block__text__content {
  text-align: justify;
  font-size: 20px;
  color: #000000;
  margin-bottom: 20px;
}

.third-step__bio__second-content-block__text__title {
  font-size: 27px;

  margin-bottom: 20px;
  color: #000000;
}
.third-step__bio__second-content-block svg {
  width: 100%;
  height: 20px;
  margin-top: -10px;
}

.third-step__button {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #000000;
  font-weight: 600;
}

.third-step__button a {
  position: relative;
  padding: 8px;
  -webkit-transition: -webkit-box-shadow 0.2s ease;
  transition: -webkit-box-shadow 0.2s ease;
  -o-transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease, -webkit-box-shadow 0.2s ease;
}

.third-step__button a:last-child {
  margin-right: 0;
}

.third-step__button a:hover {
  -webkit-box-shadow: 0 0 0 2px #000000;
          box-shadow: 0 0 0 2px #000000;
  border-radius: 2px;
}

.third-step__button a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 2px;
  background-color: #000000;
}

.third-step__button a:hover::before {
  display: none;
}


@media (max-width: 767px) {
  .third-step__bio__first-content-block__img {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .third-step__bio__first-content-block {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}