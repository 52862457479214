/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.scroll {
  position: fixed;
  top: 0;
  right: 0;
  color: #fff;
  height: 100vh;
  z-index: 999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 50px;
  padding-right: 20px;
  padding-bottom: 20px;
  width: 100px;
  grid-gap: 16px;

  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(2, 0, 36, 0)),
    color-stop(75%, rgba(0, 0, 0, 0.4)),
    to(rgba(0, 0, 0, 0.685))
  );

  background: -o-linear-gradient(
    left,
    rgba(2, 0, 36, 0) 0%,
    rgba(0, 0, 0, 0.4) 75%,
    rgba(0, 0, 0, 0.685) 100%
  );

  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(0, 0, 0, 0.4) 75%,
    rgba(0, 0, 0, 0.685) 100%
  );
}

.scroll__first-page,
.scroll__second-page,
.scroll__third-page,
.scroll__fourth-page {
  background-color: #fff;

  min-width: 10px;
  max-width: 10px;
  width: 10px;

  min-height: 10px;
  max-height: 10px;
  height: 10px;

  border-radius: 50%;
  cursor: pointer;
}

.scroll__first-page.active,
.scroll__second-page.active,
.scroll__third-page.active,
.scroll__fourth-page.active {
  outline: 2.7px solid #fff;
  outline-offset: 4px;
}

.scroll__line {
  height: 20%;
  width: 3px;
  background-color: #fff;
}
.scroll__text {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  font-size: 19px;
}

/* Стили для мобильных устройств */
@media (max-width: 767px) {
  .scroll {
    display: none;
  }
}
