/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.art-detail-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #fff;
  min-height: 100vh;
background-color: #080808;
}

.art-detail-page .container{
  padding-top: 0;
  padding-bottom: 0;
}

.art-detail-page__block {
  margin-top: -110px;
  min-height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.art-detail-page__block__sofa {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-image: url(../../../images/bg_art2.jpg);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 1300px;
}
.art-detail-page__block__sofa__container-image {
  top: 120px;
  position: absolute;
  background-color: #fff;
  -webkit-box-shadow: -12px 6px 5px 5px rgba(0, 0, 0, 0.2);
  box-shadow: -12px 6px 5px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  outline: #111 solid 6px;
  margin: 40px;
}

.art-detail-page__block__sofa__image {
  width: 100%;
  height: 100%;
  position: relative;
}

.art-detail-page__block__info {
  -webkit-box-flex: 0.7;
  -ms-flex: 0.7;
  flex: 0.7;
  width: 100%;
  min-height: 100vh;
  background-image: url(../../../images/bg_art2_r.jpg);
  background-position: top left;
  background-size: cover;
}

/* .art-detail-page__block__info {
  width: 100%;
  min-height: 100vh;
  background-image: url(../../../images/bg_art2_r.jpg);
  background-position: top left;
  background-size: cover;
} */

.art-detail-page__block__info .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-around;
  padding-top: 110px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #080808c7;
  min-height: 100vh;
}

.art-detail-page__block__info__content__links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-gap: 20px;
  font-size: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-items: center;
}

.prev-painting-arrow {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: prev;
}
.next-painting-arrow {
  -ms-grid-row: 3;
  -ms-grid-column: 5;
  grid-area: next;
}

.art-detail-page__block__info__content__links .prev-painting-arrow,
.art-detail-page__block__info__content__links .next-painting-arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #53535391;
  width: 60px;
  height: 35px;
  border-radius: 5px;

}

.art-detail-page__block__info__content {
  height: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.art-detail-page__block__info img {
  -ms-grid-row: 1;
  -ms-grid-row-span: 5;
  -ms-grid-column: 3;
  grid-area: img;
  max-width: 420px;
  width: 100%;
  padding: 20px;
}

.shadow-for-header {
  z-index: 5;
  position: absolute;
  height: 110px;
  width: 100%;

  -webkit-box-shadow: -3px 5px 11px 6px rgb(0 0 0 / 47%);
  box-shadow: -3px 5px 11px 6px rgb(0 0 0 / 47%);
  background-color: #11111144;
}

.dimensions-info {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: right;
  font-size: 12px;
}
.dimensions-info__height {
  height: 100%;
  margin-left: -27px;
  text-align: center;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  white-space: nowrap;
}

.dimensions-info__width {
  width: 100%;
  margin-bottom: -20px;
  text-align: center;
  white-space: nowrap;
}

.art-detail-page__block__info__content__characteristic {
  width: 100%;
}

.change-color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-gap: 8px;
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 20px;
}
.change-color div {
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.change-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-gap: 8px;
  position: absolute;
  left: 0;
  bottom: 40px;
  margin: 20px;
}
.change-image div {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.picture-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.for-sale__detail-page {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 600;
  margin-top: 50px;
}

.for-sale__detail-page__email {
  text-decoration: underline;
}

.for-sale__detail-page div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.for-sale__detail-page div {

}

.art-detail-page__block__info__content__title {
  text-align: center;
  text-transform: uppercase;
}

.art-detail-page__block__sofa__container-image 
.art-detail-page__block__info__content__links {
  display: none;
  position: absolute;
  left: 0;
  bottom: -80px;
}

/* Стили для 4х4 устройств */
@media (max-width: 1280px) {
  .art-detail-page__block__sofa__container-image 
.art-detail-page__block__info__content__links  {
  display: flex;
}
  .art-detail-page__block__info .container {
    padding-top: 0;
  }
  .art-detail-page__block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .art-detail-page__block__sofa {
    min-height: 100vh;
  }

  .art-detail-page__block__info {
    margin-top: 0;
    background-color: #111;
    border-radius: 0;
  }
}

/* Стили для мобильных устройств */
@media (max-width: 767px) {
  .art-detail-page__block__sofa {
    min-height: 120vh;
    background-size: cover !important;
  }
  .art-detail-page__block__info .container {
    padding-top: 0;
  }
  .art-detail-page__block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .art-detail-page__block__info {
    margin-top: 0;
    background-color: #111;
    border-radius: 0;
  }
}
