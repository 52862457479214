/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

body.modal-open  {
  overflow: hidden;
}

.blog-page {
  width: 100%;
  min-height: 100vh;
}

.blog-page__links {
  color: #FFF;
  margin-bottom: 50px;
}

.blog-page__links a {
  margin-right: 20px;
  text-decoration: underline ;
}

.eapps-facebook-feed-inner {
background-color: #111!important;
border: none!important;
}

.eapps-facebook-feed-posts-item {
background-color:  #111!important;
}

.eui-item-text {
color: #FFF!important;
}

.eapps-facebook-feed-item-author-name a{
color: #FFF!important;
}

.eapps-widget-toolbar {
display: none!important;
}

.eapps-facebook-feed-posts-item-content-link-caption-meta-description,
.eapps-facebook-feed-posts-item-content-link-caption {
  color: #FFF!important;
}