/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.first-step {
  background-image: url(../../../images/bg1.jpg);
  height: 100vh;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-box-shadow: 0px 15px 142px 129px rgba(0, 0, 0, 0.4) inset;
  box-shadow: 0px 15px 142px 129px rgba(0, 0, 0, 0.4) inset;
}

.first-step__content-block {
  width: 100%;
  height: calc(100% - 110px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(90%, rgba(2, 0, 36, 0)),
    color-stop(95%, rgba(0, 0, 0, 0.4)),
    to(rgba(0, 0, 0, 0.685))
  );
  background: -o-linear-gradient(
    top,
    rgba(2, 0, 36, 0) 90%,
    rgba(0, 0, 0, 0.4) 95%,
    rgba(0, 0, 0, 0.685) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 0) 90%,
    rgba(0, 0, 0, 0.4) 95%,
    rgba(0, 0, 0, 0.685) 100%
  );
}

.first-step__content-block__left-side {
  width: 100%;
  height: 100%;
  padding: 25px 60px 20px 60px;

  display: -ms-grid;

  display: grid; /* Добавили grid */
  -ms-grid-rows: 1fr 1fr 1fr 1fr 0.5fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 0.5fr; /* Добавили строки для элементов */
  -ms-grid-columns: 0.6fr 2fr 2fr;
  grid-template-columns: 0.6fr 2fr 2fr;
  grid-template-areas:
    ". . ."
    ". . ."
    ". . ."
    ". author author "
    "links links langs";
}

.first-step__content-block__quote {
  grid-area: quote;
  color: #fff;
  text-align: left;
  font-size: 20px;
}

.first-step__content-block__author {
  -ms-grid-row: 4;
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  grid-area: author;
}
.first-step__content-block__author .first-step__content-block__author__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #ffffff;
  border-left: 2px solid #fff;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.first-step__content-block__author__name {
  margin-bottom: 15px;
  font-size: 42px;
}

.first-step__content-block__author__quote {
  margin-top: 15px;
  font-size: 22px;
}
.first-step__content-block__links {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  width: 100%;
  grid-area: links;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  color: #fff;
  -moz-column-gap: 20px;
  -webkit-column-gap: 20px;
  column-gap: 20px;
}

.first-step__content-block__links .facebook::after {
  content: "facebook";
}
.first-step__content-block__links .instagram::after {
  content: "instagram";
}

.first-step__content-block__langs {
  -ms-grid-row: 5;
  -ms-grid-column: 3;
  grid-area: langs;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  grid-gap: 20px;
  color: #fff;
  margin-right: 50px;
}

.first-step__content-block__langs div {
  cursor: pointer;
}

/* Стили для мобильных устройств */
@media (max-width: 767px) {
  .first-step__content-block__langs {
    margin-right: 0;
  }
  .first-step__content-block__links .facebook::after,
  .first-step__content-block__links .instagram::after {
    content: "";
  }

  .first-step__content-block__links .facebook,
  .first-step__content-block__links .instagram {
    width: 30px;
    height: 30px;
  }

  .first-step__content-block__links .facebook {
    background-image: url(../../../images/icons/facebook_l.png);
    background-size: cover;
  }
  .first-step__content-block__links .instagram {
    background-image: url(../../../images/icons/instagram_l.png);
    background-size: cover;
  }

  .first-step {
    -webkit-box-shadow: 0px -53px 103px 83px rgba(0, 0, 0, 0.4) inset;
    box-shadow: 0px -53px 103px 83px rgba(0, 0, 0, 0.4) inset;
    background-position: right;
  }
  .first-step__content-block__left-side {
    padding: 25px 15px 20px 15px;
    -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-columns: 0.2fr 1fr 0.2fr;
    grid-template-columns: 0.2fr 1fr 0.2fr;
    grid-template-areas:
      "author author author "
      ". . ."
      ". . ."
      ". . ."
      "quote quote quote"
      "links links langs";
  }
  .first-step__content-block__quote {
    -ms-grid-row: 5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }
  .first-step__content-block__author {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }
  .first-step__content-block__links {
    -ms-grid-row: 6;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }
  .first-step__content-block__langs {
    -ms-grid-row: 6;
    -ms-grid-column: 3;
  }
}
