/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.art__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-gap: 50px;
}
.art__block__card {
  position: relative;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  color: #fff;
  max-width: 360px;
  min-height: 700px;
  width: 100%;
  padding: 20px;
}

.art__block__card__img {
  position: relative;
  z-index: 2;
  border-radius: 4px;
  max-height: 320px;
  margin: auto;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.4);
}

.art__block__card__title {
  text-transform: uppercase;
  font-weight: 100;
}

.art__block__card__info {
  max-height: 162px;
  overflow-y: auto;
}

.art__block__card__info__description {
  text-align: justify;
}

.art__block__card:hover {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
  -webkit-box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.4);
}

.for-sale {
  height: 0px;
  overflow: hidden;
  display: flex;
  padding: 0px;
  justify-content: center;
  align-items: center;
  -webkit-transition: height 0.5s ease, padding 0.5s ease; /* Добавлено правило для анимации height и padding */
  -o-transition: height 0.5s ease, padding 0.5s ease;
  transition: height 0.5s ease, padding 0.5s ease;
  border-radius: 4px;
  background-color: rgb(0, 225, 255);
  color: #000;
  margin-bottom: -5px;
  font-weight: 600;
}

.art__block__card:hover .for-sale {
  padding: 5px;
  height: 90px;
  display: flex;
}

.for-sale div {
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.for-sale__contacts-block {
  display: flex;
  grid-gap: 10px 30px;
  flex-wrap: wrap;
}

.for-sale__contacts-block__text,
.for-sale__contacts-block__link {
  width: fit-content;
  cursor: pointer;
  text-decoration: underline;
  font-size: 17px;
}


.for-sale .for-sale__contacts-block__link {
  padding: 5px 0;
}