.container {
    padding: 25px 60px;
    height: 100%;
    width: 100%;
    max-width: 2350px;
    margin-right: auto;
    margin-left: auto;
}

/* Стили для мобильных устройств */
@media (max-width: 767px) {
    .container {
        padding: 25px 15px;
    }
}