/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.header {
  width: 100%;
  position: relative;
  z-index: 999;
}
.header .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 110px;
  padding-top: 0;
  padding-bottom: 0;
}

.logo {
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  position: relative;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 80px;

  font-size: 18px;
}
.logo__image {
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.logo__text {
  font-size: 20px;
  color: #fff;
  letter-spacing: -1px;
  margin-left: 20px;
}
.logo:hover {
  -webkit-filter: drop-shadow(5px 5px 5px #111);
  filter: drop-shadow(5px 5px 5px #111);
}

.navbar__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-area: menu;
  color: #fff;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-weight: 600;
  -moz-column-gap: 100px;
  -webkit-column-gap: 100px;
  column-gap: 100px;
}

.navbar__menu a {
  position: relative;
  padding: 8px;
  -webkit-transition: -webkit-box-shadow 0.2s ease;
  transition: -webkit-box-shadow 0.2s ease;
  -o-transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease;
  transition: box-shadow 0.2s ease, -webkit-box-shadow 0.2s ease;
}

.navbar__menu a:last-child {
  margin-right: 0;
}

.navbar__menu a:hover {
  -webkit-box-shadow: 0 0 0 2px #fff;
  box-shadow: 0 0 0 2px #fff; /* Добавляем белую рамку вокруг активной ссылки */
  border-radius: 2px;
}

/* Добавьте этот стиль для border-top на половине блока */
.navbar__menu a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 2px;
  background-color: #fff;
}

.navbar__menu a:hover::before {
  display: none;
}

.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar .nav-container a:hover {
  font-weight: bolder;
}

.nav-container {
  position: relative;
  display: none;

  height: 60px;
  width: 60px;
}

.nav-container .checkbox {
  position: absolute;
  display: block;
  height: 26px;
  width: 32px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
  margin: 0;
}

.nav-container .hamburger-lines {
  display: block;
  height: 26px;
  width: 32px;
  position: absolute;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.nav-container .hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
}

.nav-container .hamburger-lines .line1 {
  -webkit-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  transition: -webkit-transform 0.4s ease-in-out;
  -o-transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
  -webkit-transform-origin: 0% 100%;
  -ms-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: -webkit-transform 0.4s ease-in-out;
  transition: -webkit-transform 0.4s ease-in-out;
  -o-transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.nav-container input[type="checkbox"]:checked #menuBlock {
  display: none;
}

.nav-container input.checkbox:checked ~ .navbar {
  position: fixed;
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#menu-open {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nav-container#menu-open {
  position: fixed;
  z-index: 5;

  top: 26px;
  right: 15px;
}

.header__langs {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-gap: 20px;
}
.header__langs div {
  cursor: pointer;
}

.header__langs--menu {
  display: none;
}

/* Стили для мобильных устройств */
@media (max-width: 1015px) {
  .header__langs--header {
    display: none;
  }
  .header__langs--menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  /* Скрываем меню, чтобы оно появлялось только при нажатии на кнопку */
  .navbar__menu {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    row-gap: 30px;
    -moz-column-gap: 20px;
    -webkit-column-gap: 20px;
    column-gap: 20px;
  }

  .header .container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .logo {
    text-align: left;
  }
  .navbar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar__menu {
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
  }

  .navbar__menu#menu-open {
    position: fixed;
    top: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    margin-left: 20%;
    width: 80%;
    background-color: rgba(0, 0, 0, 0.658);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    padding: 20px;
  }

  /* Показываем кнопку меню */
  .nav-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
