/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.contacts_widget_bg {
    background-color: rgba(0, 0, 0, 0.342) ;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    top: 0;
    z-index: 8;
}
.contacts_bg--close {
    cursor: pointer;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}
.contacts_widget_block {
    position: relative;
    z-index: 9;
    max-width: 500px;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: rgba(255, 255, 255, 0.575);
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    border-radius: 5px;
    padding: 10px 30px 30px 30px;
}

.contacts_widget_block__close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    width: 20px;
    height: 20px;
    background-image: url(../../images/icons/close.png);
    background-size: cover;
}

.contacts_widget_block__close-button:hover {
    -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-filter: drop-shadow(5px 5px 5px #00000085);
            filter: drop-shadow(5px 5px 5px #00000085);
}

.contacts_widget_block__content__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-filter: invert(100%);
            filter: invert(100%);
}
.contacts_widget_block__content__links__instagram {
    width: 30px;
    height: 30px;
    background-image: url(../../images/icons/instagram_l.png);
    background-size: cover; 
}
.contacts_widget_block__content__links__facebook {
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    width: 30px;
    height: 30px;
    background-image: url(../../images/icons/facebook_l.png);
    background-size: cover;
}

.contacts_widget_block__content__links__facebook:hover {
    background-image: url(../../images/icons/facebook.png);
}

.contacts_widget_block__content__links__instagram:hover {
    background-image: url(../../images/icons/instagram.png);
}