/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.footer {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 5;
}

.footer .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 10px;
}

.footer__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  color: #fff;
  margin-right: 129px;
  margin-bottom: 100px;
  grid-gap: 50px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.footer__block__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-gap: 20px;
}
.footer__block__column__title {
  font-weight: 600;
  font-size: 22px;
}
.footer__block__column__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 16px;
  grid-gap: 10px;
}
.footer__block__copyright {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  color: #fff;
  font-size: 12px;
}

.footer__block__column__text__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-top: 10px;
}
.footer__block__column__text__links__instagram {
  width: 30px;
  height: 30px;
  background-image: url(../../images/icons/instagram_l.png);
  background-size: cover;
}
.footer__block__column__text__links__facebook {
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  width: 30px;
  height: 30px;
  background-image: url(../../images/icons/facebook_l.png);
  background-size: cover;
}

.footer__block__column__text__links__facebook:hover {
  background-image: url(../../images/icons/facebook.png);
}

.footer__block__column__text__links__instagram:hover {
  background-image: url(../../images/icons/instagram.png);
}

@media (max-width: 767px) {
  .footer__block {
    margin-right: 0;
  }
}
