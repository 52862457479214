@font-face {
  font-family: "Montsetrrat";
  src: url("../public/fonts/Montserrat-Medium.ttf") format("truetype");
}

body {
  font-family: "Montsetrrat", sans-serif;
  background-color: #111;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* W3C standard
   сейчас только для Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(255, 255, 255) rgb(0, 0, 0);
}

/* для Chrome/Edge/Safari */
*::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: rgb(0, 0, 0);
}
*::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  border: 3px solid rgb(0, 0, 0);
}

